
















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class FscCard extends Vue {
  public name = "FscCard";

  @Prop({ default: () => ({}) })
  public customClassDict!: Record<string, string>;

  public get hasHeaderSlot(): boolean {
    return !!this.$slots.header;
  }

  public get hasFooterSlot(): boolean {
    return !!this.$slots.footer;
  }

  public get wrapperComputedClasses(): string[] {
    const defaultClasses: Record<string, string> = {
      layout: "d-flex flex-column",
      background: "bg-white",
      borderRadius: "rounded-10",
      padding: "py-3 px-20",
      overflow: "overflow-hidden fsc-fix-screen",
    };

    // Merge default classes with custom classes
    const mergedClasses = { ...defaultClasses, ...this.customClassDict };

    // Return all class values as an array for Vue's class binding
    return Object.values(mergedClasses);
  }
}
