





































































import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import LMSDashboardCover from "@/views/LMSDashboard/LMSDashboardCover.vue";
import LMSDashboardLink from "@/views/LMSDashboard/LMSDashboardLink.vue";
import DashboardAppointments from "@/views/Dashboard/DashboardAppointments.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import DashboardProfileSection from "@/views/Dashboard/DashboardProfileSection.vue";
import LMSDashboardLinkWithDescription from "@/views/LMSDashboard/LMSDashboardLinkWithDescription.vue";

@Component({
  components: { LMSDashboardLinkWithDescription, LMSDashboardCover, LMSDashboardLink, DashboardProfileSection, FscSimpleCard, DashboardAppointments },
})
export default class LMSDashboard extends mixins() {
  public name = "LMSDashboard.vue";
}
