export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";
export const SET_PDF_MIME_TYPE = "SET_PDF_MIME_TYPE";
export const SET_PNG_MIME_TYPE = "SET_PNG_MIME_TYPE";
export const SET_LOGO_MIME_TYPE = "SET_LOGO_MIME_TYPE";
export const SET_PDF_SUCCESS = "SET_PDF_SUCCESS";
export const SET_PNG_SUCCESS = "SET_PNG_SUCCESS";
export const SET_LOGO_SUCCESS = "SET_LOGO_SUCCESS";
export const SET_PDF_ITEM = "SET_PDF_ITEM";
export const SET_PNG_ITEM = "SET_PNG_ITEM";
export const SET_LOGO_ITEM = "SET_LOGO_ITEM";
export const SET_PDF_FILENAME = "SET_PDF_FILENAME";
export const SET_PNG_FILENAME = "SET_PNG_FILENAME";
export const SET_LOGO_FILENAME = "SET_LOGO_FILENAME";
export const SET_DRIVING_SCHOOL_SWITCHES = "SET_DRIVING_SCHOOL_SWITCHES";
export const SET_SIGNATURE_STATUSES = "SET_SIGNATURE_STATUSES";
export const SET_QR_CODE_URL = "SET_QR_CODE_URL";
export const SET_DRIVE_BUZZ_ONBOARDING_LINKS = "SET_DRIVE_BUZZ_ONBOARDING_LINKS";
export const SET_COURSE_QR_CODE_URL = "SET_COURSE_QR_CODE_URL";
export const SET_DRIVING_SCHOOL_IS_USING_MIRROR_VEHICLE = "SET_DRIVING_SCHOOL_IS_USING_MIRROR_VEHICLE";
