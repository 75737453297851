import {
  MODULE_ACCOUNTING,
  MODULE_AUTOMATIC_PAYMENTS,
  MODULE_COMMUNICATION,
  MODULE_COMPANY,
  MODULE_COST_BEARERS,
  MODULE_CUSTOMERS,
  MODULE_EXAM,
  MODULE_REPORTS,
  MODULE_TASK,
  MODULE_THEORY,
  MODULE_TIME_PLANNER,
} from "@/constants/Permissions";
import i18n from "@/i18n";

// Note: Make sure all paths are unique
// If you add '/pathA' to FSCSidebarItems it should not be added '/pathA' to LMSSidebarItems
export const FSCSidebarItems = [
  {
    title: i18n.t("sidebar.dashboard").toString().toUpperCase(),
    path: "/",
    icon: "house",
    bgColor: "#f5f5f5",
  },
  {
    title: i18n.t("sidebar.communication"),
    path: "/communication",
    icon: "envelope-dot",
    meta: { title: "" },
    can: MODULE_COMMUNICATION,
    badge: "CommunicationBadge",
    children: [
      {
        title: i18n.t("sidebar.onboarding"),
        path: "/registration",
        badge: "RegistrationBadge",
      },
      {
        title: i18n.t("auth.email"),
        path: "/email",
        badge: "EmailBadge",
      },
      {
        title: i18n.t("sidebar.broadcast"),
        path: "/broadcast",
      },
      {
        title: i18n.t("sidebar.messaging"),
        path: "/messaging",
      },
    ],
  },
  {
    title: i18n.t("sidebar.todo"),
    path: "/todo",
    icon: "list-check",
    can: MODULE_TASK,
  },
  {
    title: i18n.t("sidebar.students"),
    path: "/students",
    icon: "address-card",
    can: MODULE_CUSTOMERS,
  },
  {
    title: i18n.t("students.payers"),
    path: "/payers",
    icon: "building",
    can: MODULE_COST_BEARERS,
  },
  {
    title: i18n.t("sidebar.scheduler"),
    path: "/scheduler",
    icon: "calendar-days",
    can: MODULE_TIME_PLANNER,
  },
  {
    title: i18n.t("sidebar.theory_lessons"),
    path: "/theory",
    icon: "screen-users",
    can: MODULE_THEORY,
  },
  {
    title: i18n.t("students.exams"),
    path: "/exams",
    icon: "graduation-cap",
    can: MODULE_EXAM,
    children: [
      {
        title: i18n.t("sidebar.theory_exams"),
        path: "/theory-exam",
      },
      {
        title: i18n.t("sidebar.practical_exams"),
        path: "/practical-exams",
      },
    ],
  },
  {
    title: i18n.t("sidebar.reports"),
    path: "/reports",
    icon: "chart-pie",
    can: MODULE_REPORTS,
    children: [
      {
        title: i18n.t("sidebar.finances"),
        path: "/finances",
      },
      {
        title: i18n.t("sidebar.training"),
        path: "/training",
      },
      {
        title: i18n.t("sidebar.planning"),
        path: "/planning",
      },
      {
        title: i18n.t("sidebar.learn"),
        path: "/learn",
      },
      {
        title: i18n.t("sidebar.export"),
        path: "/export",
      },
      {
        title: i18n.t("general.cost_invoice"),
        path: "/invoice",
      },
    ],
  },
  {
    title: i18n.t("sidebar.automatic_payments"),
    path: "/automatic-payments",
    icon: "wallet",
    can: MODULE_AUTOMATIC_PAYMENTS,
    children: [
      {
        title: i18n.t("sidebar.automatic_payments_overview"),
        path: "/automatic-payments-overview",
      },
      {
        title: i18n.t("sidebar.automatic_payments_classic_pay"),
        path: "/automatic-payments-classic-pay",
      },
      {
        title: i18n.t("sidebar.automatic_payments_live_pay"),
        path: "/automatic-payments-live-pay",
      },
      {
        title: i18n.t("sidebar.automatic_payments_flash_pay"),
        path: "/automatic-payments-flash-pay",
      },
      {
        title: i18n.t("sidebar.automatic_payments_payment_documents"),
        path: "/automatic-payments-payment-documents",
      },
    ],
  },
  {
    title: i18n.t("sidebar.accounting"),
    path: "/accounting",
    icon: "calculator-simple",
    can: MODULE_ACCOUNTING,
    children: [
      {
        title: i18n.t("sidebar.fibufix"),
        path: "/fibu-fix",
        external: true,
      },
      {
        title: i18n.t("sidebar.persofix"),
        path: "/perso-fix",
      },
    ],
  },
  {
    title: i18n.t("sidebar.company"),
    path: "/company",
    icon: "car-building",
    can: MODULE_COMPANY,
    children: [
      {
        title: i18n.t("general.base_data"),
        path: "/master-data",
      },
      {
        title: i18n.t("sidebar.configurations"),
        path: "/configurations",
      },
      {
        title: i18n.t("sidebar.accounting"),
        path: "/accounting",
      },
      {
        title: i18n.t("sidebar.instructors"),
        path: "/instructors",
        alias: ["/instructors/create", "/instructors/edit/:id"],
      },
      {
        title: i18n.t("sidebar.vehicles"),
        path: "/vehicles",
      },
      {
        title: i18n.tc("general.product", 2),
        path: "/products",
      },
      {
        title: i18n.t("general.price_list"),
        path: "/price-lists",
      },
      {
        title: i18n.tc("general.branch", 2),
        path: "/branches",
      },
    ],
  },
  {
    title: i18n.t("sidebar.help"),
    path: "https://support.fahrschulcockpit.de/portal/de/home",
    icon: "user-nurse",
    external: true,
  },
];

export const LMSSidebarItems = [
  {
    title: i18n.t("sidebar.dashboard").toString().toUpperCase(),
    path: "/lms",
    icon: "house",
  },
  {
    title: "Prüfungsfragen",
    path: "/lms-questions",
    icon: "lightbulb-on",
    bgColor: "#10649c",
    can: MODULE_EXAM,
    children: [
      {
        title: "Der Fragentutor",
        path: "/lms-question-tutor",
        badge: "EmailBadge",
        can: MODULE_TASK,
        bgColor: "white",
        bgImg: `url(${require(`@/assets/lms-fragentutor-bg.png`)})`,
      },
    ],
  },
  {
    title: "Wissen",
    path: "/lms-knowledge",
    icon: "book",
    can: MODULE_EXAM,
    disabled: true,
  },
  {
    title: "Theorie",
    path: "/lms-theory",
    icon: "screen-users",
    can: MODULE_EXAM,
    disabled: true,
  },
  {
    title: "Selbst Lernen",
    path: "/lms-self-learning",
    icon: "chalkboard-user",
    can: MODULE_EXAM,
    disabled: true,
  },
  {
    title: "Fahren",
    path: "/lms-driving",
    icon: "car",
    can: MODULE_EXAM,
    disabled: true,
  },
  {
    title: "Reporting",
    path: "/lms-reporting",
    icon: "ballot-check",
    can: MODULE_EXAM,
    disabled: true,
  },
  {
    title: "Konfiguration",
    path: "/lms-configuration",
    icon: "gears",
    can: MODULE_EXAM,
    disabled: true,
  },
];

export const GetBgColorByPath = (context: string, path: string): string => {
  let searchContext: any[] = [{}];
  if (context == "fsc") {
    searchContext = FSCSidebarItems;
  } else {
    searchContext = LMSSidebarItems;
  }

  // First, try to find the item in the main list
  const mainItem = searchContext.find((item) => item.path === path);
  if (mainItem && mainItem.bgColor) {
    return mainItem.bgColor;
  }

  // If not found, check the children of each main item
  for (const item of searchContext) {
    if (item.children) {
      const childItem = item.children.find((child: { path: string }) => child.path === path);
      if (childItem && childItem.bgColor) {
        return childItem.bgColor;
      }
    }
  }

  // Return default if no matching path or bgColor is found
  return "#f5f5f5";
};

export const GetBgImageByPath = (context: string, path: string): any => {
  let searchContext: any[] = [{}];
  if (context == "fsc") {
    searchContext = FSCSidebarItems;
  } else {
    searchContext = LMSSidebarItems;
  }

  // First, try to find the item in the main list
  const mainItem = searchContext.find((item) => item.path === path);
  if (mainItem && mainItem.bgImg) {
    return mainItem.bgImg;
  }

  // If not found, check the children of each main item
  for (const item of searchContext) {
    if (item.children) {
      const childItem = item.children.find((child: { path: string }) => child.path === path);
      if (childItem && childItem.bgImg) {
        return childItem.bgImg;
      }
    }
  }

  // Return default if no matching path or bgColor is found
  return null;
};

export const ExportSidebarItemPaths = (items: any[]) => {
  let paths: any[] = [];

  items.forEach((item) => {
    // Add the main item path
    if (item.path) {
      paths.push(item.path);
    }

    // If the item has children, recursively extract their paths
    if (item.children && Array.isArray(item.children)) {
      paths = paths.concat(ExportSidebarItemPaths(item.children));
    }
  });

  return paths;
};
